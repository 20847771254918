import './src/styles/all.sass'

export const onRouteUpdate = () => {
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {
    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {
        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);
        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');
        // remove is-active if navbar-item is clicked
        const $navbarItems = Array.prototype.slice.call(document.querySelectorAll('.navbar-item'), 0);
        $navbarItems.forEach( item => {
          item.addEventListener('click', () => {
            el.classList.remove('is-active');
            $target.classList.remove('is-active');
          })
        })
      });
    });
  }
}
